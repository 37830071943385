<template>
  <div class="gastos mx-2">
    <v-row>
      <v-col cols="12">
        <v-card>
          <v-card-title> Gastos del día </v-card-title>
          <v-card-text>
            <v-row dense>
              <v-col cols="12" md="12" sm="12">
                <v-data-table :loading="loading_t" :headers="headers" :items="filter_list" hide-default-footer
                  :items-per-page="perPage" :page.sync="page">
                  <template v-slot:top>
                    <v-row dense>
                      <v-col cols="12" md="3" sm="6" order="2">
                        <v-menu :nudge-right="20" transition="scale-transition" offset-y min-width="290px"
                          :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="date_ini" outlined rounded dense label="Fecha inicial"
                              append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker scrollable v-model="date_ini" locale="es-co" color="primary"
                            first-day-of-week="1"></v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="3" sm="6" order="3">
                        <v-menu :nudge-right="20" transition="scale-transition" offset-y min-width="290px"
                          :close-on-content-click="false">
                          <template v-slot:activator="{ on, attrs }">
                            <v-text-field v-model="date_fin" outlined rounded dense label="Fecha final"
                              append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
                          </template>
                          <v-date-picker scrollable v-model="date_fin" locale="es-co" color="primary"
                            first-day-of-week="1">
                          </v-date-picker>
                        </v-menu>
                      </v-col>
                      <v-col cols="12" md="3" sm="6" order="4">
                        <v-select :items="categorias" v-model.number="categoria" outlined rounded dense
                          label="Categoría" @change="loadGastos()"></v-select>
                      </v-col>
                      <v-col cols="12" md="3" sm="12" order="1" order-md="4">
                        <v-btn dark color="black" @click="dialog = true">
                          Registrar gasto
                        </v-btn>
                      </v-col>
                      <v-col cols="11" md="6" sm="5" order="5">
                        <v-text-field outlined rounded dense label="Buscar" v-model="search"
                          @keydown.enter.prevent="loadGastos"></v-text-field>
                      </v-col>
                      <v-col cols="12" md="3" sm="3" order="6">
                        <div style="display: flex;">
                          <v-subheader>
                            Gastos X pag
                          </v-subheader>
                          <div style="width:120px">
                            <v-select :items="itemsPerPage" v-model="perPage" outlined dense @change="setItemsPerPage">
                            </v-select>
                          </div>
                        </div>
                      </v-col>
                      <v-col cols="12" md="2" sm="3" order="7">
                        <v-btn dark color="black" class="mr-2" @click="downloadGastos()">
                          <v-icon left>mdi-download</v-icon>
                          Descargar
                        </v-btn>
                      </v-col>
                      <v-col cols="12" md="6" order="8">
                        <v-pagination dense v-model="page" :length="lengthPagination" :total-visible="6"
                          @input="changePage"></v-pagination>
                      </v-col>
                    </v-row>
                  </template>
                  <template v-slot:item.obs="{ item }">
                    <v-tooltip top max-width="200">
                      <template v-slot:activator="{ on, attrs }">
                        <span v-on="on" v-bind="attrs" class="d-inline-block text-truncate" style="max-width: 100px">
                          {{ item.obs }}
                        </span>
                      </template>
                      <span>{{ item.obs }}</span>
                    </v-tooltip>
                  </template>
                  <template v-slot:item.subtotal="{ item }">
                    {{ item.subtotal | currency }}
                  </template>
                  <template v-slot:item.iva="{ item }">
                    {{ item.iva | currency }}
                  </template>
                  <template v-slot:item.total="{ item }">
                    {{ item.total | currency }}
                  </template>
                  <template v-slot:item.estado="{ item }">
                    <v-chip small :color="item.estado ? 'success' : 'error'">
                      <span v-if="item.estado">Activo</span>
                      <span v-else>Anulado</span>
                    </v-chip>
                  </template>
                  <template v-slot:item.acciones="{ item }">
                    <v-menu :ref="`menuActions_${item.id}`" :nudge-width="200" offset-x offset-y><template
                        v-slot:activator="{ on: menu, attrs }">
                        <v-tooltip top>
                          <template v-slot:activator="{ on: tooltip }">
                            <v-btn fab small color="primary" v-on="{ ...tooltip, ...menu }" v-bind="attrs">
                              <v-icon>mdi-dots-vertical</v-icon>
                            </v-btn>
                          </template>
                          <span>Acciones</span>
                        </v-tooltip>
                      </template>
                      <v-list>
                        <router-link :to="'/print_gasto/' + item.id" target="_blank">
                          <v-list-item @click="cancelMenu(item.id, 1)">
                            <v-list-item-icon>
                              <v-icon color="primary">mdi-printer</v-icon>
                            </v-list-item-icon>
                            <v-list-item-title>Imprimir</v-list-item-title>
                          </v-list-item>
                        </router-link>
                        <v-menu :ref="`subMenuActions_${item.id}`" :nudge-width="200" offset-x offset-y
                          v-if="item.estado">
                          <template v-slot:activator="{ on: submenu, subattrs }">
                            <v-list-item v-on="submenu" v-bind="subattrs">
                              <v-list-item-icon>
                                <v-icon color="error">mdi-delete</v-icon>
                              </v-list-item-icon>
                              <v-list-item-title>Anular</v-list-item-title>
                            </v-list-item>
                          </template>
                          <v-card>
                            <v-card-title>Anular</v-card-title>
                            <v-card-text>
                              <div style="font-size: 1.3em">
                                ¿Estas seguro de anular el gasto?
                              </div>
                            </v-card-text>
                            <v-card-actions>
                              <v-spacer></v-spacer>
                              <v-btn text color="primary" @click="cancelMenu(item.id, 2)">Cancelar</v-btn>
                              <v-btn @click="deleteGasto(item)" dark color="black">Anular</v-btn>
                            </v-card-actions>
                          </v-card>
                        </v-menu>
                      </v-list>
                    </v-menu>
                  </template>
                  <template v-slot:body.append>
                    <tr>
                      <th colspan="5">Total</th>
                      <th>{{ totalizacion.subtotal | currency }}</th>
                      <th>{{ totalizacion.iva | currency }}</th>
                      <th colspan="3">{{ totalizacion.total | currency }}</th>
                    </tr>
                  </template>
                </v-data-table>
                <v-dialog v-model="dialog" persistent scrollable max-width="600px">
                  <v-card>
                    <v-card-title> Registrar gasto </v-card-title>
                    <v-card-text>
                      <v-row dense>
                        <v-col cols="12" md="12" sm="12">
                          <formComponent :list="form" ref="gastos" />
                        </v-col>
                        <v-col cols="12" md="12" sm="12">
                          <h3>Total: {{ total | currency }}</h3>
                        </v-col>
                      </v-row>
                    </v-card-text>
                    <v-card-actions>
                      <v-spacer></v-spacer>
                      <v-btn text @click="cancel()" color="primary">
                        Cancelar
                      </v-btn>
                      <v-btn :dark="!loading" :color="!loading ? 'black' : 'gray'" :loading="loading"
                        :disabled="loading" @click="registrar()">
                        Registrar gasto
                      </v-btn>
                    </v-card-actions>
                  </v-card>
                </v-dialog>
              </v-col>
            </v-row>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import XLSX from "xlsx";
import * as moment from "moment";
moment.locale("es");
import formComponent from "../../components/Form.vue";
export default {
  name: "gastos",
  components: {
    formComponent,
  },
  data: () => ({
    loading: false,
    loading_t: false,
    perPage: 25,
    page: 1,
    headers: [],
    date_ini: moment().format('YYYY-MM-DD'),
    date_fin: moment().format('YYYY-MM-DD'),
    categorias: [],
    categoria: 0,
    dialog: false,
    search: '',
    paginationServe: {
      total: 0,
      perPage: 25,
    },
    gastos: [],
    profesionales: [],
    itemsPerPage: [
      {
        text: 10,
        value: 10,
      },
      {
        text: 25,
        value: 25,
      },
      {
        text: 50,
        value: 50,
      },
      {
        text: 100,
        value: 100,
      },
      {
        text: 500,
        value: 500,
      },
    ],
    headers: [
      {
        text: "Fecha",
        value: "fecha",
        align: "start",
        sortable: false,
        width: 120
      },
      {
        text: "Nombre gasto",
        value: "gasto",
        sortable: false,
        width: 120
      },
      {
        text: "Categoría",
        value: "categoria",
      },
      {
        text: "Profesional",
        value: "profesional",
      },
      {
        text: "Observación",
        value: "obs",
        sortable: false,
      },
      {
        text: "Subtotal",
        value: "subtotal",
        sortable: false,
      },
      {
        text: "IVA",
        value: "iva",
        sortable: false,
      },
      {
        text: "Total",
        value: "total",
      },
      {
        text: "Estado",
        value: "estado",
        sortable: false,
      },
      {
        text: "",
        value: "acciones",
        sortable: false,
      },
    ],
    form: {
      ref: "gastos",
      list: [
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: "picker",
          menu: {
            model: false,
            label: "Fecha",
          },
          validation: {
            valid: false,
          },
          id: "fecha",
          model: moment().format("YYYY-MM-DD"),
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "text",
            label: "Nombre del gasto",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "gasto",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: "select",
          select: {
            label: "Categoria",
            items: [],
            hint: "Selecciona una categoria",
            multiple: false,
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "categoria",
          model: undefined,
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: "select",
          select: {
            label: "Profesional",
            items: [],
            hint: "Selecciona un profesional",
            multiple: false,
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "profesional",
          model: undefined,
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 12,
            sm: 12,
          },
          input: {
            type: "textarea",
            input_type: "",
            label: "Observacion del gasto",
            rows: 2,
          },
          validation: {
            valid: false,
            rules: "",
          },
          id: "obs",
          model: "",
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Subtotal",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "subtotal",
          model: 0,
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 6,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "IVA",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "iva",
          model: 0,
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Efectivo",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "efectivo",
          model: 0,
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Transferencia",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "transfer",
          model: 0,
          show: true,
        },
        {
          grid: {
            cols: 12,
            md: 4,
            sm: 6,
          },
          input: {
            type: "input",
            input_type: "number",
            label: "Datafono",
          },
          validation: {
            valid: true,
            rules: "required",
          },
          id: "datafono",
          model: 0,
          show: true,
        },
      ],
      profesionales: [],
    },
  }),
  methods: {
    loadGastos() {
      this.loading_t = true;
      const body = {
        route: `/gastos?page=${this.page}`,
        params: {
          date: {
            inicio: this.date_ini,
            fin: this.date_fin,
          },
          categoria: this.categoria,
          perPage: this.paginationServe.perPage,
        },
      };
      this.$store
        .dispatch("get_data", body)
        .then((response) => {
          if (response.data.success) {
            this.paginationServe.total = response.data.total;
            this.gastos = response.data.data.data;
          }
        })
        .finally(() => (this.loading_t = false));
    },
    loadCategorias() {
      const body = {
        route: "/categorias",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.categorias = response.data.data.map((c) => {
            return { text: c.categoria, value: c.id };
          });
          this.categorias.unshift({ value: -1, text: "Gastos Anulados" });
          this.categorias.unshift({ value: -2, text: "Gastos Activos" });
          this.categorias.unshift({ value: 0, text: "Todas" });
          const index = this.form.list.findIndex(
            (element) => element.id == "categoria"
          );
          this.form.list[index].select.items = response.data.data.map((c) => {
            return { text: c.categoria, value: c.id };
          });
        }
      });
    },
    loadProfesionales() {
      const body = {
        route: "/profesionales_sede",
      };
      this.$store.dispatch("axios_get", body).then((response) => {
        if (response.data.success) {
          this.profesionales = response.data.data.map((p) => {
            return { text: p.nombre, value: p.id };
          });
          const index = this.form.list.findIndex(
            (element) => element.id == "profesional"
          );
          this.profesionales.unshift(
            {
              header: "Gastos del centro",
            },
            {
              divider: true,
            },
            {
              text: "Cargar al centro",
              value: 0,
            },
            {
              divider: true,
            },
            {
              header: "Gastos por profesional",
            },
            {
              divider: true,
            }
          );
          this.form.list[index].select.items = this.profesionales;
        }
      });
    },
    registrar() {
      this.$refs.gastos.validate().then((res) => {
        if (res) {
          this.loading = true;
          const data = this.$store.getters.validation_form(this.form.list);
          let body = {
            route: "/register_gasto",
            data,
          };
          body.data.subtotal = parseFloat(body.data.subtotal);
          body.data.iva = parseFloat(body.data.iva);
          body.data.efectivo = parseFloat(body.data.efectivo);
          body.data.transfer = parseFloat(body.data.transfer);
          body.data.datafono = parseFloat(body.data.datafono);
          const metodos =
            body.data.efectivo + body.data.transfer + body.data.datafono;
          if (metodos >= this.total) {
            this.$store
              .dispatch("axios_post", body)
              .then((response) => {
                if (response.data.success) {
                  let alert = {
                    alert: true,
                    color: "success",
                    text: response.data.data,
                  };
                  this.$store.dispatch("setAlert", alert);
                  this.cancel();
                  this.loadGastos();
                }
              })
              .catch((error) => {
                let alert = {
                  alert: true,
                  color: "error",
                  text: error.response.data.data,
                };
                this.$store.dispatch("setAlert", alert);
              })
              .finally(() => (this.loading = false));
          } else {
            this.loading = false;
          }
        }
      });
    },
    deleteGasto(item) {
      const body = {
        route: "/delete_gasto",
        data: {
          id: item.id,
        },
      };

      this.$store
        .dispatch("axios_post", body)
        .then((response) => {
          if (response.data.success) {
            let alert = {
              alert: true,
              color: "success",
              text: response.data.data,
            };
            this.$store.dispatch("setAlert", alert);
            this.cancelMenu(item.id, 2);
            this.loadGastos();
          }
        })
        .catch((error) => {
          let alert = {
            alert: true,
            color: "error",
            text: error.response.data.data,
          };
          this.$store.dispatch("setAlert", alert);
        });
    },
    changePage(data) {
      this.gastos = [];
      this.page = data;
      this.loadGastos();
    },
    setItemsPerPage(val) {
      if (val === -1) this.paginationServe.perPage = this.paginationServe.total;
      else this.paginationServe.perPage = val;

      this.loadGastos();
    },
    downloadGastos() {
      const name = "Lista-gastos";
      const data = this.gastos.map((g) => {
        return {
          Fecha: moment(g.fecha).format("ll"),
          Gasto: g.gasto,
          Categoria: g.categoria,
          Profesional: g.profesional,
          Observacion: g.observacion,
          Subtotal: g.valor,
          IVA: g.iva,
          Total: parseFloat(g.valor) + parseFloat(g.iva),
          Efectivo: g.efectivo,
          Transferencia: g.transfer,
          Datafono: g.datafono,
          Estado: g.estado ? 'Activo' : 'Anulado'
        };
      });
      let excel = XLSX.utils.json_to_sheet(data);
      const workbook = XLSX.utils.book_new();
      const filename = name;
      XLSX.utils.book_append_sheet(workbook, excel, filename);
      XLSX.writeFile(workbook, `${filename}.xlsx`);
    },
    cancel() {
      this.loading = false;
      this.dialog = false;
      this.setForm();
      this.$refs.gastos.reset();
    },
    setForm() {
      this.form.list.forEach((item) => {
        if (item.id != undefined) {
          item.model = "";
          if (item.id == "fecha") {
            item.model = moment().format("YYYY-MM-DD");
          }
          if (item.id == "categoria") {
            item.model = undefined;
          }
          if (item.id == "profesional") {
            item.model = undefined;
          }
          if (item.id == "subtotal") {
            item.model = 0;
          }
          if (item.id == "iva") {
            item.model = 0;
          }
          if (item.id == "efectivo") {
            item.model = 0;
          }
          if (item.id == "transfer") {
            item.model = 0;
          }
          if (item.id == "datafono") {
            item.model = 0;
          }
        }
      });
    },
    cancelMenu(id, type) {
      const menu = `menuActions_${id}`;
      const submenu = `subMenuActions_${id}`;

      if (type === 2) {
        this.$refs[menu].isActive = false;
        this.$refs[submenu].isActive = false;
      } else this.$refs[menu].isActive = false;
    },
  },
  created() {
    this.loadCategorias()
    this.loadGastos()
    this.loadProfesionales()
  },
  mounted() {
    this.$store.dispatch("loadNav", true);
    const body = {
      permisos: JSON.stringify(["recepcion", "informes"]),
    };
    this.$store
      .dispatch("tokenValid", body)
      .then((response) => {
        if (!response.data.access_view) {
          this.$router.push("/home");
        }
      })
      .catch((error) => {
        console.log(error);
        if (error.response.status == 401) {
          localStorage.removeItem("access_token");
          localStorage.removeItem("session_start");
          window.location.reload();
        }
      });
  },
  computed: {
    list() {
      return this.gastos.map((g) => {
        const total = parseFloat(g.valor) + parseFloat(g.iva);
        return {
          id: g.id,
          fecha: moment(g.fecha).format("ll"),
          gasto: g.gasto,
          categoria: g.categoria,
          profesional: g.profesional,
          obs: g.observacion,
          subtotal: parseFloat(g.valor),
          iva: parseFloat(g.iva),
          total,
          estado: g.estado,
        };
      });
    },
    filter_list() {
      return this.list.filter(item => {
        const removeAccents = (str) => {
          return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        }
        let gasto = removeAccents(item.gasto.toLowerCase())
        let categoria = removeAccents(item.categoria.toLowerCase())
        let search = removeAccents(this.search.toLowerCase())

        return gasto.includes(search) || categoria.includes(search)
      })
    },
    total() {
      const index_s = this.form.list.findIndex(
        (element) => element.id == "subtotal"
      );

      const index_i = this.form.list.findIndex(
        (element) => element.id == "iva"
      );

      const subtotal = parseFloat(this.form.list[index_s].model) || 0;
      const iva = parseFloat(this.form.list[index_i].model) || 0;

      return subtotal + iva;
    },
    lengthPagination() {
      function is_decimal(val) {
        if (val % 1 == 0) {
          return true;
        } else {
          return false;
        }
      }
      let number = this.paginationServe.total / this.paginationServe.perPage;
      if (!is_decimal(number)) number = parseInt(number) + 1;
      return number;
    },
    totalizacion() {
      let calculate_reduce = (array, value) => {
        return array.reduce((index, item) => {
          return index + parseFloat(item[value])
        }, 0)
      }

      return {
        subtotal: calculate_reduce(this.filter_list, 'subtotal'),
        iva: calculate_reduce(this.filter_list, 'iva'),
        total: calculate_reduce(this.filter_list, 'total'),
      }
    }
  },
  watch: {
    date_ini() {
      this.loadGastos()
      return
    },
    date_fin() {
      this.loadGastos()
      return
    }
  },
};
</script>